import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/Seo'
import Carousel from '../components/Carousel/Carousel'
import Article from '../components/Article/Article'

const eventsTemplate = ( { data: { strapi: { makeoverStory } }, pageContext, location } ) => {


	const { seoDescription, storyImages, storyContent, storyLeftIllustration, storyRightIllustration, storySubTitle, storyTitle  } = makeoverStory
	const images = storyImages.length ? storyImages : null

	return (
		<>
			<SEO
				title={ `${ storyTitle } | Makeover Days` }
				description={ seoDescription }
				pathname={ location.pathname } />

			<Article 
				title={ storyTitle } 
				subTitle={ storySubTitle } 
				content={ storyContent } 
				leftIllustration={ storyLeftIllustration } 
				rightIllustration={ storyRightIllustration } 
				isMakeover={ true }>
				{ images &&
					<Carousel id={ pageContext.id } images={ images } />
				}
			</Article>
		</>
	)
}

export default eventsTemplate

export const query = graphql`
	query makeoverImagesQuery($id: ID!) {
		strapi {
			makeoverStory( id: $id ) {
				seoDescription
				storyContent
				storyLeftIllustration
				storyRightIllustration
				storySubTitle
				storyTitle
				storyImages {
					url
					id
					imageFile {
						childImageSharp {
							fluid(maxWidth: 1450, fit: CONTAIN) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
				}
			}
		}
	}
`