import React, { useState, useEffect, useRef } from 'react';
import Img from "gatsby-image"

import {
	RiArrowLeftSLine,
	RiArrowRightSLine } from 'react-icons/ri'

export default function Carousel ( props ) {

	const { images } = props
	const RESIZE_DEBOUNCE = 150
	const carouselRef = useRef()

	const getWidth = () => carouselRef.current.offsetWidth

	const DEFAULT_TAB_TRANSITION = 500
	const tabTransitionDuration = 400 || DEFAULT_TAB_TRANSITION
	const STYLE_CLS_NAMES = {
		SLIDE_RIGHT: 'slide-right',
		SLIDE_LEFT: 'slide-left',
		SLIDE_BACK: 'slide-back'
	}

	const totalImages = images.length
	const isSingleImage = totalImages === 1 ? true : false
	const initialImages = totalImages === 1 ? [ images[ 0 ] ] : [ images[ totalImages - 1 ], images[ 0 ], images[ 1 ] ]

	const [ currentSlide, setCurrentSlide ] = useState( 0 )
	const [ previousSlide, setPreviousSlide ] = useState( totalImages - 1 )
	const [ nextSlide, setNextSlide ] = useState( 1 )
	const [ width, setWidth ] = useState( 0 )
	const [ slider, setSlider ] = useState( initialImages )
	const [ transitionClass, setTransitionClass ] = useState( '' )
	const [ touchStart, setTouchStart ] = useState( 0 )
	const [ touchEnd, setTouchEnd ] = useState( 0 )

	useEffect( () => {

		if ( carouselRef ) {
			setWidth( carouselRef.current.offsetWidth )
		}

		// timeoutId for debounce mechanism
		let timeoutId = null
		const resizeListener = () => {
			// prevent execution of previous setTimeout
			clearTimeout( timeoutId )
			// change width from the state object after 150 milliseconds
			timeoutId = setTimeout( () => setWidth( getWidth() ), RESIZE_DEBOUNCE )
		};

		// set resize listener
		window.addEventListener( 'resize', resizeListener )

		// clean up function
		return () => {
			// remove resize listener
			window.removeEventListener( 'resize', resizeListener )
		}
	}, [] )

	/**
	 * Allows images to infinite loop when incremented/numbers are negative, image at correct positive index returned
	 * @param {Number} imageIndex - images at index
	 * @param {Number} totalImages -- total number of images
	 */
	const modulus = ( imageIndex, totalImages ) => {
		return ( ( imageIndex % totalImages ) + totalImages ) % totalImages
	}

	const handleButtonClick = ( increment ) => {
		const slideRight = increment === 1
		const transitionClass = slideRight ? STYLE_CLS_NAMES.SLIDE_RIGHT : STYLE_CLS_NAMES.SLIDE_LEFT
		const updatedSlides = slideRight
			? [ images[ currentSlide ], images[ nextSlide], images[ modulus( nextSlide + 1, totalImages ) ] ]
			: [ images[ modulus( ( previousSlide - 1 ), totalImages) ], images[ previousSlide ], images[ currentSlide ] ]

		setTransitionClass( transitionClass )

		setTimeout( () => {
			setSlider( updatedSlides )
			setTransitionClass( '' )
		}, tabTransitionDuration / 2 )

		setPreviousSlide( slideRight ? currentSlide : modulus( previousSlide - 1, totalImages ) )
		setCurrentSlide( slideRight ? nextSlide : previousSlide )
		setNextSlide( slideRight ? modulus( nextSlide + 1, totalImages ) : currentSlide )
	}

	function handleTouchStart( e ) {
		if ( isSingleImage ) {
			return
		}

		setTransitionClass( '' )
		setTouchStart( e.targetTouches[ 0 ].clientX )
	}

	function handleTouchMove( e ) {
		if ( isSingleImage ) {
			return
		}

		setTouchEnd( e.targetTouches[ 0 ].clientX )
	}

	function handleTouchEnd() {

		if ( isSingleImage ) {
			return
		}

		const margin = width < 500 ? 50 : 150

		if ( touchStart - touchEnd > margin) {
			// do your stuff here for left swipe
			handleButtonClick( 1 )
		} else if ( touchStart - touchEnd < -margin ) {
			// do your stuff here for right swipe
			handleButtonClick( -1 )
		} else {
			setTransitionClass( STYLE_CLS_NAMES.SLIDE_BACK )
		}

		setTouchStart( 0 )
		setTouchEnd( 0 )
	}

	useEffect( () => {
		let movement = -width - ( - ( touchEnd - touchStart ) )

		if ( movement > 0 ) {
			movement = 0
		} else if ( movement < -width * 2 ) {
			movement = -width * 2
		}

		carouselRef.current.style.transform = `translateX(${ movement }px)`

	}, [ touchEnd ])

	return (
		<div className="carousel">
			<div
				ref={ carouselRef }
				className={`carousel__slider-container ${ transitionClass }`}
				style={{ transform: `translateX(-${ isSingleImage ? 0 : width }px)`}}
				onTouchStart={ e => handleTouchStart(e)}
				onTouchMove={ e => handleTouchMove(e)}
				onTouchEnd={() => handleTouchEnd()}
			>
				{ slider.map ( ( slide, index ) => (
					<div className="carousel__slider-item" key={ index }>
						<Img fluid={{ ...slide.imageFile.childImageSharp.fluid, aspectRatio: 16/9 }} className="carousel__slider-image" />
					</div>
				) ) }
			</div>

			<RiArrowLeftSLine
				className={`carousel__button carousel__button--prev ${ isSingleImage ? 'u-hide' : '' }`}
				onClick={ () => handleButtonClick( -1 ) } />

			<RiArrowRightSLine
				className={`carousel__button carousel__button--next ${ isSingleImage ? 'u-hide' : '' }`}
				onClick={ () => handleButtonClick( 1 ) } />
		</div>
	)
}